/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        //*Mobile Menu*//
        $( ".c-header__toggle" ).click(function(e) {
          e.preventDefault();

          $('.c-mobile__menu').animate({height: 'toggle'});
        });

        // for the mobile animation

        $(".c-header__toggle").click(function(){
          $(this).toggleClass("is-active");
        });


        //   hero Image
        var hero = $('.hero-back').data('desktop'),
        hero_mobile = $('.hero-back').data('mobile');
        if(viewportWidth < 767 ){
          $('.hero-back').css('background-image', 'url('+hero_mobile+')');
        }else{
          $('.hero-back').css('background-image', 'url('+hero+')');
        }

        //   Intro Image
        var intro = $('.section--intro').data('desktop'),
        intro_mobile = $('.section--intro').data('mobile');
        if(viewportWidth < 767 ){
          $('.section--intro').css('background-image', 'url('+intro_mobile+')');
        }else{
          $('.section--intro').css('background-image', 'url('+intro+')');
        }

        //   Amenities Image
        var amenitie = $('.a-back').data('desktop'),
        amenitie_mobile = $('.a-back').data('mobile');
        if(viewportWidth < 767 ){
          $('.a-back').css('background-image', 'url('+amenitie_mobile+')');
        }else{
          $('.a-back').css('background-image', 'url('+amenitie+')');
        }

        //   Activities Image
        var activities = $('.activites-back').data('desktop'),
        activities_mobile = $('.activites-back').data('mobile');
        if(viewportWidth < 767 ){
          $('.activites-back').css('background-image', 'url('+activities_mobile+')');
        }else{
          $('.activites-back').css('background-image', 'url('+activities+')');
        }

        //   Testimonials Image
        var testimonial = $('.section--testimonials').data('desktop'),
        testimonial_mobile = $('.section--testimonials').data('mobile');
        if(viewportWidth < 767 ){
          $('.section--testimonials').css('background-image', 'url('+testimonial_mobile+')');
        }else{
          $('.section--testimonials').css('background-image', 'url('+testimonial+')');
        }



        // Mobile Drop Menu Primary Nav
        $(function () {
          var children = $('.f-nav > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
          $('<span class="drop-menu"><i class="fas fa-chevron-down"></i></span>').insertAfter(children);
          $('.f-nav .drop-menu').click(function (e) {
            $(this).next().slideToggle(300);
            return false;
          });
        });



        // logo on scroll
        var elWrap = $('html');

        if (viewportWidth >= 50) {
          $(window).scroll(function() {
            var scrollX = $(this).scrollTop();

            if (scrollX >= 10) {
              $(elWrap).addClass('is-scrolling');
            } else {
              $(elWrap).removeClass('is-scrolling');
            }
          });
        }

        if ($('.js-property-gallery').length) {
          $('.js-property-gallery').slick({
            arrows: true,
            asNavFor: '.js-property-gallery-nav',
            dots: false,
            fade: true,
            nextArrow: '<button type="button" class="slick-prev" aria-label="Previous Gallery Item"><i class="fas fa-chevron-left"></i></button>',
            prevArrow: '<button type="button" class="slick-next" aria-label="Next Gallery Item"><i class="fas fa-chevron-right"></i></button>',
            rows: 0,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
          });
        }

        if ($('.js-property-gallery-nav').length) {
          $('.js-property-gallery-nav').slick({
            arrows: false,
            asNavFor: '.js-property-gallery',
            autoplay: true,
            dots: false,
            focusOnSelect: true,
            rows: 0,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 3,
          });
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          // tasks to do if it is a Mobile Device
          $(function () {
            var children = $('.c-nav__list > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
            $('<span class="drop-menu"><i class="fas fa-chevron-down"></i></span>').insertAfter(children);
            $('.c-nav__list .drop-menu').click(function (e) {
              $(this).next().slideToggle(300);
              return false;
            });
          });

        }

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
